import { Suspense } from "react";
import { useRouteLoaderData, json, defer, Await } from "react-router-dom";
import { BASE_URL, SEARCH_PEOPLE_CRITERIA } from "../../../Constants";
import commonStyles from "../../../commonStyles.module.scss";
import { doLogout, getAuthToken } from "../../../util/auth";
import SearchedPeopleGridView from "./SearchedPeopleGridView";
import { fetchSearchedPersonsSuccess } from "../../../store/reducers/friendsSlice";
import { getFilterStr } from "../../../util/filterUtil";
import { Loader } from "../../UI/ErrorModal";
import { authActions } from "../../../store/reducers/authSlice";
import { textFor } from "../../../util/languages";

function SearchedPeopleLoader(props) {
  //since user will alway search and will expecct fresh result,
  //no need to avoid calling api again for this.
  const { routeLoaderData } = useRouteLoaderData("searchedPeople");
  return (
    <>
      <div className={commonStyles.topPanel} style={{ color: "black" }}>
        {textFor("searchResults:")}
      </div>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => (
            <SearchedPeopleGridView props={routeLoaderData} />
          )}
        </Await>
      </Suspense>
    </>
  );
}

export default SearchedPeopleLoader;

export async function loadSearchedPeople(dispatch) {
  return defer({ routeLoaderData: fetchSearchedPeople(dispatch, 0, 0) });
}

export async function fetchSearchedPeople(
  dispatch,
  reqId,
  pageNumber,
  wait = 5
) {
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  const obj = reduxFriends.searchedPersons;
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
  let objLastFetched = 0;
  if (Object.keys(obj).includes("lastFetchedOn")) {
    objLastFetched = obj.lastFetchedOn;
  }
  const diff = currentTimestampInSeconds - objLastFetched;
  console.log("timediff ........ ", diff);
  if (diff < wait && reqId === 0) {
    //wait for wait seconds before calling again
    return [];
  }

  if (pageNumber > 0) {
    //for very first req, when there is no data at all, then obj.totalPages would not be available
    pageNumber = parseInt(pageNumber) % parseInt(obj.totalPages);
  }

  const searchCriteria = JSON.parse(
    localStorage.getItem(SEARCH_PEOPLE_CRITERIA)
  );
  const filterStr = getFilterStr(searchCriteria);
  const token = getAuthToken();
  let url =
    BASE_URL +
    "/normalUser/searchPersons?pageSize=12&pageNumber=" +
    pageNumber +
    "&reqId=" +
    reqId +
    filterStr;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log(
      "response from fetch during fetchFriendsAndReqSummary = ",
      response
    );

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json(
          { message: "Could not fetch details for selected event." },
          {
            status: 500,
          }
        );
      }
    } else {
      const resData = await response.json();
      console.log("got response ", resData);

      //Only update the redux if we got more/less data otherwise from App
      //we will get update on state from redux and it will be infinite loop.
      //ideally we should compare the elements not just the length.
      //but that should be fine, because we will keep redux data up-to-date
      //when we will add/update or delete the friends.
      if (Object.keys(resData).length > 0 && resData.elements.length > 0) {
        dispatch(fetchSearchedPersonsSuccess(resData));
      }
      return resData;
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during friends fetch:", error);
    const status = error.status;
    console.error("status = ", status);
    return error;
  }
}
