import EmailField from "./emailField/EmailField";
import PhoneField from "./emailField/PhoneField";
import PasswordField from "./emailField/PasswordField";

const LoginCredentials = ({ registrationDetails }) => {
  return (
    <div style={{ padding: "2em 0" }}>
      <EmailField registrationDetails={registrationDetails} />
      <PhoneField registrationDetails={registrationDetails} />
      <PasswordField registrationDetails={registrationDetails} />
    </div>
  );
};
export default LoginCredentials;
