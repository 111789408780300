import { redirect } from "react-router-dom";
import { loadProfile } from "../pages/ProfileLoader";
import { loadAppSettings } from "../pages/Settings";
import { loadFamilyMembers } from "../pages/familyMembers/FamilyMembersLoader";
import { loadFriends } from "../pages/friends/FriendsLoader";
import { loadSearchedPeople } from "../components/friendsAndRequests/searchPeopleToConnect/SearchedPeopleLoader";
import { loadSavedGroomLists } from "../pages/searchGroom/GroomListsLoader";
import { loadSearchedGrooms } from "../pages/searchGroom/SearchedGroomLoader";
import { loadListMembers } from "../pages/listMembers/ListMemberLoader";
import {
  LANGUAGE_KEY,
  LAST_PAGE,
  LOGIN_STATUS,
  USER_INPUT_TYPE,
} from "../Constants";
import { delayForMs } from "./apiUtil";
import { fetchStarted } from "../store/reducers/savedGroomListsSlice";

export function getAuthToken() {
  const token = localStorage.getItem("token");
  // console.log("checking token: ", token);
  return token;
}

export function keepOnWelcomeIfNoToken() {
  const token = getAuthToken();
  // debugger;
  // console.log("checking token during keepOnWelcomeIfNoToken loader=", token);
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    return token;
  }
}
export async function keepOnWelcomeIfNoTokenElseGetFamilyMembers(
  dispatch,
  reduxFamilyMembers
) {
  const token = getAuthToken();
  // debugger;
  console.log(
    "checking token during keepOnWelcomeIfNoTokenElseGetAllFamilyMembers loader=",
    token
  );
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const familyMembers = await loadFamilyMembers(dispatch, reduxFamilyMembers);
    // console.log("fetched familyMembers in loader = ", familyMembers); //it will be a deferred value
    return familyMembers;
  }
}
export async function keepOnWelcomeIfNoTokenElseGetFriends(dispatch) {
  const token = getAuthToken();
  // debugger;
  console.log(
    "checking token during keepOnWelcomeIfNoTokenElseGetAllFamilyMembers loader=",
    token
  );
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const friends = await loadFriends(dispatch);
    // console.log("fetched friends in loader = ", friends); //it will be a deferred value
    return friends;
  }
}
export async function keepOnWelcomeIfNoTokenElseGetSettings(dispatch) {
  const token = getAuthToken();
  // debugger;
  console.log(
    "checking token during keepOnWelcomeIfNoTokenElseGetSettings loader=",
    token
  );
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const appSettings = await loadAppSettings(dispatch);
    // console.log("appSettings in loader = ", appSettings);
    return appSettings;
  }
}

export async function keepOnWelcomeIfNoTokenElseSearchGroomsByCriteria(
  dispatch
) {
  function noSearchGroomFilter() {
    if (localStorage.getItem("searchGroomCriteria") != null) {
      return false;
    }
    return true;
  }
  const token = getAuthToken();
  if (!token || noSearchGroomFilter()) {
    console.log("no token, redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const searchedGrooms = await loadSearchedGrooms(dispatch);
    return searchedGrooms;
  }
}
export async function keepOnWelcomeIfNoTokenElseFetchSavedGroomLists(
  dispatch,
  savedGroomLists
) {
  const token = getAuthToken();
  // debugger;
  // console.log(
  //   "checking token during keepOnWelcomeIfNoTokenElseFetchSavedGroomLists loader=",
  //   token
  // );
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    console.log(
      "In else part of the keepOnWelcomeIfNoTokenElseFetchSavedGroomLists"
    );
    const savedLists = await loadSavedGroomLists(dispatch, savedGroomLists);
    return savedLists;
  }
}
export async function keepOnWelcomeIfNoTokenElseSearchPeople(dispatch) {
  const token = getAuthToken();
  // debugger;
  console.log(
    "checking token during keepOnWelcomeIfNoTokenElseGetSettings loader=",
    token
  );
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const searchedPeople = await loadSearchedPeople(dispatch);
    return searchedPeople;
  }
}

export async function keepOnWelcomeIfNoTokenElseLoadListMembers(
  dispatch,
  reduxSavedGroomLists,
  request,
  params
) {
  const token = getAuthToken();
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const listMembers = await loadListMembers(
      dispatch,
      reduxSavedGroomLists,
      request,
      params
    );
    return listMembers;
  }
}

export async function keepOnWelcomeIfNoTokenElseGetProfileData(
  dispatch,
  userProfile
) {
  const token = getAuthToken();
  // debugger;
  // console.log(
  //   "checking token during keepOnWelcomeIfNoTokenElseGetProfileData loader=",
  //   token
  // );
  if (!token) {
    console.log("redirecting to /welcome.");
    return redirect("/welcome");
  } else {
    const profileDetails = await loadProfile(dispatch, userProfile);
    // console.log("profileDetails in loader = ", profileDetails);
    return profileDetails;
  }
}

export function keepOnDashboardIfLoggedIn() {
  const token = getAuthToken();
  // debugger;
  console.log(
    "checking during keepOnDashboardIfLoggedIn loader, token =",
    token
  );
  if (token) {
    let lastPage = localStorage.getItem(LAST_PAGE);
    if (!lastPage) {
      lastPage = "/dashboard";
    }

    console.log("redirecting to ", lastPage);
    if (lastPage !== "/welcome") {
      return redirect(lastPage);
    } else {
      return null;
    }
  } else {
    return token;
  }
}
export function keepOnDashboardOrWelcome() {
  const token = getAuthToken();
  console.log("checking token during keepOnDashboardOrWelcome loader=", token);
  if (token) {
    console.log("doing redirect to /dashboard");
    return redirect("/dashboard");
  } else {
    console.log("doing redirect to /welcome");
    return redirect("/welcome");
  }
}
export function doLogout(dispatch, authActions) {
  console.log("doing logout...............................");
  dispatch(authActions.logoutSuccess());
  clearLocalStorage();
  return "logout";
}

export function clearLocalStorage() {
  const language = localStorage.getItem(LANGUAGE_KEY);
  const loginStatus = localStorage.getItem(LOGIN_STATUS);
  const userInputType = localStorage.getItem(USER_INPUT_TYPE);
  const phoneOrEmail = localStorage.getItem("phoneOrEmail");
  const otp = localStorage.getItem("otp");
  const resetPasswordOtp = localStorage.getItem("resetPasswordOtp");
  localStorage.clear();
  localStorage.setItem(LANGUAGE_KEY, language);
  if (loginStatus) {
    localStorage.setItem(LOGIN_STATUS, loginStatus);
  }
  if (userInputType) {
    localStorage.setItem(USER_INPUT_TYPE, userInputType);
    localStorage.setItem("otp", otp);
    localStorage.setItem("phoneOrEmail", phoneOrEmail);
    localStorage.setItem("resetPasswordOtp", resetPasswordOtp);
  }
}

export function doNavigate(navigate, path) {
  console.log("setting lastPage to ", path);
  localStorage.setItem(LAST_PAGE, path);
  if (path !== "/dashboard") {
    navigate(path);
  }
}
