// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const pendingPhoneSlice = createSlice({
  name: "pendingPhone",
  initialState: {
    pendingPhone: "",
    pendingPhoneOtp: "",
  },
  reducers: {
    clearPendingPhone: (state, action) => {
      state.pendingPhone = "";
      state.pendingPhoneOtp = "";
    },
    setPendingPhone: (state, action) => {
      state.pendingPhone = action.payload.pendingPhone;
      state.pendingPhoneOtp = action.payload.pendingPhoneOtp;
    },
  },
});

export default pendingPhoneSlice.reducer;
export const { clearPendingPhone, setPendingPhone } = pendingPhoneSlice.actions;
