import { useState } from "react";
import classes from "./PasswordSetup.module.scss";
import { textFor } from "../../util/languages";
import { isAnEmail } from "../../util/fieldChecker";
import commonStyles from "../../commonStyles.module.scss";

function PasswordSetup() {
  const phoneOrEmail = localStorage.getItem("phoneOrEmail");
  const [password, setPassword] = useState("welcome");
  const [otp, setOtp] = useState("123456");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  function getUsernameLabel() {
    if (isAnEmail(localStorage.getItem("phoneOrEmail"))) {
      return textFor("email");
    } else {
      return textFor("phone");
    }
  }
  return (
    <div style={{ margin: "2vw 0 0 2vw" }}>
      <div style={{ color: "white" }}>{textFor("pleaseEnterOtpPassword:")}</div>
      <div>
        <label htmlFor="otp">OTP:</label>
        <br />
        <input
          type="number"
          id="otp"
          value={otp}
          name="otp"
          placeholder={textFor("6digits")}
          style={{ maxWidth: "5em" }}
          onChange={handleOtpChange}
        />
      </div>
      <div style={{ marginTop: "1vh" }}>
        <label style={{ color: "black" }} htmlFor="password">
          {textFor("password:")}
        </label>
        <br />
        <input
          type="password"
          id="password"
          value={password}
          name="password"
          placeholder="At least 8 characters"
          onChange={handlePasswordChange}
        />
      </div>
      <div style={{ marginTop: "1vh" }}>
        <label htmlFor="phoneOrEmail">{getUsernameLabel()}</label>
        <br />
        <input
          className={classes.readOnlyInput}
          type="text"
          id="phoneOrEmail"
          name="phoneOrEmail"
          value={phoneOrEmail}
          readOnly={true}
        />
      </div>
      <div style={{ marginTop: "1vh" }}>
        <button
          className={commonStyles.purpleButton}
          type="button"
          // disabled={isSubmitting}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default PasswordSetup;
