import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
export function getGenderType(props) {
  let genderType = "";
  //check if props.registrationDetails contains genderType or the value
  genderType = props.registrationDetails.genderType;
  return genderType;
}

export function getFamilyRelationType(props) {
  let familyRelationType = "";
  if (props.registrationDetails.familyRelationType) {
    familyRelationType = props.registrationDetails.familyRelationType;
  }
  return familyRelationType;
}

export function getFullName(props) {
  let fullName = "";
  if (props.registrationDetails.fullName) {
    fullName = props.registrationDetails.fullName;
  }
  return fullName;
}
export function getMaritalStatusType(props) {
  let maritalStatusType = "";
  if (props.registrationDetails.maritalStatusType) {
    maritalStatusType = props.registrationDetails.maritalStatusType;
  }
  return maritalStatusType;
}
export function getWeightRangeType(props) {
  let weightRangeType = "";
  if (props.registrationDetails.weightRangeType) {
    weightRangeType = props.registrationDetails.weightRangeType;
  }
  return weightRangeType;
}
export function getHeightFeetType(props) {
  let heightFeetType = "";
  if (props.registrationDetails.heightFeetType) {
    heightFeetType = props.registrationDetails.heightFeetType;
  }
  return heightFeetType;
}

export function getHeightInchesType(props) {
  let heightInchesType = "";
  if (props.registrationDetails.heightInchesType) {
    heightInchesType = props.registrationDetails.heightInchesType;
  }
  return heightInchesType;
}
export function getBirthYear(props) {
  let birthYear = "";
  if (props.registrationDetails.birthYear) {
    birthYear = props.registrationDetails.birthYear;
  }
  return birthYear;
}

export function getSpecialAbilityDetails(props) {
  let specialAbilityDetails = "";
  if (props.registrationDetails.specialAbilityDetails) {
    specialAbilityDetails = props.registrationDetails.specialAbilityDetails;
  }
  return specialAbilityDetails;
}

export function getIsSpeciallyAbled(props) {
  let isSpeciallyAbled = "";
  if (props.registrationDetails.isSpeciallyAbled !== undefined) {
    isSpeciallyAbled = props.registrationDetails.isSpeciallyAbled;
  }
  return isSpeciallyAbled;
}

export function getIsPureVegetarian(props) {
  let isPureVegetarian = "";
  if (props.registrationDetails.isPureVegetarian !== undefined) {
    isPureVegetarian = props.registrationDetails.isPureVegetarian;
  }
  return isPureVegetarian;
}
export function getEducationLevelType(props) {
  let educationLevelType = "";
  if (props.registrationDetails.educationLevelType) {
    educationLevelType = props.registrationDetails.educationLevelType;
  }
  return educationLevelType;
}

export function getEducationDetails(props) {
  let educationDetails = "";
  if (props.registrationDetails.educationDetails) {
    educationDetails = props.registrationDetails.educationDetails;
  }
  return educationDetails;
}
export function getGotraType(props) {
  let gotraType = "";
  if (props.registrationDetails.gotraType) {
    gotraType = props.registrationDetails.gotraType;
  }
  return gotraType;
}

export function getYearlyIncome(props) {
  let yearlyIncome = "";
  if (props.registrationDetails.yearlyIncome) {
    yearlyIncome = props.registrationDetails.yearlyIncome;
  }
  return yearlyIncome;
}
export function getIncomeSourceDetails(props) {
  let incomeSourceDetails = "";
  if (props.registrationDetails.incomeSourceDetails) {
    incomeSourceDetails = props.registrationDetails.incomeSourceDetails;
  }
  return incomeSourceDetails;
}
export function getOtherDetails(props) {
  let otherDetails = "";
  if (props.registrationDetails.otherDetails) {
    otherDetails = props.registrationDetails.otherDetails;
  }
  return otherDetails;
}
export function getShouldShowChallengeDetails(props) {
  if (props.registrationDetails.specialAbilityDetails) {
    return true;
  }
  return false;
}

export function getShowEduDetails(props) {
  if (props.registrationDetails.educationDetails) {
    return true;
  }
  return false;
}
export function getProfileImageUrl(props) {
  let profileImageUrl = props.registrationDetails.profileImageUrl;
  if (!props.registrationDetails.profileImageUrl) {
    profileImageUrl = userPlaceholderImageUrl;
  }
  return profileImageUrl;
}
export function getIsEditing(props) {
  let isEditing = props.registrationDetails.isEditing;
  if (!props.registrationDetails.isEditing) {
    isEditing = false;
  }
  return isEditing;
}
export function getKundliDoshas(obj) {
  let kundliDoshas = [];

  if (obj.isManglikDosh) {
    kundliDoshas.push("Mangal_Dosha");
  }

  if (obj.isChandraDosh) {
    kundliDoshas.push("Chandra_Dosha");
  }

  if (obj.isGuruChandalDosh) {
    kundliDoshas.push("Guru_Chandal_Dosha");
  }

  if (obj.isKaalSarpaDosh) {
    kundliDoshas.push("Kaal_Sarpa_Dosha");
  }

  if (obj.isKemadrumaDosh) {
    kundliDoshas.push("Kemadruma_Dosha");
  }

  if (obj.isNadiDosh) {
    kundliDoshas.push("Nadi_Dosha");
  }

  if (obj.isPitraDosh) {
    kundliDoshas.push("Pitra_Dosha");
  }

  if (obj.isRahuDosh) {
    kundliDoshas.push("Rahu_Dosha");
  }

  if (obj.isVyatipataDosh) {
    kundliDoshas.push("Vyatipata_Dosha");
  }
  return kundliDoshas;
}
