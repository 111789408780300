import { useEffect, useState } from "react";
import { Form, json, Link, useNavigation } from "react-router-dom";
import { isAnEmail, isAPhoneNumber } from "../../util/fieldChecker";
import { BASE_URL, USER_INPUT_TYPE } from "../../Constants";
import commonStyles from "../../commonStyles.module.scss";
import { textFor } from "../../util/languages";
import { Loader } from "../../components/UI/ErrorModal";
import { saveMaskedPhoneOrEmail } from "../../util/viewUtils";

function Register() {
  const navigation = useNavigation();
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [password, setPassword] = useState("welcome");
  const [otp, setOtp] = useState("");
  const isSubmitting = navigation.state === "submitting";
  const [isOtpAvailable, setIsOtpAvailable] = useState("");
  useEffect(() => {
    if (localStorage.getItem("otp")) {
      setIsOtpAvailable("1");
      setOtp(localStorage.getItem("otp"));
    }
  }, [isSubmitting]);
  const handlePhoneOrEmailChange = (e) => {
    setPhoneOrEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleOtpChange = (e) => {
    if (e.target.value.length < 7) {
      setOtp(e.target.value);
    }
  };

  function getTextForEmail() {
    const savedPhoneOrEmail = localStorage.getItem("phoneOrEmail");
    return textFor("yourEmailIs") + savedPhoneOrEmail;
  }

  function getTextForPhone() {
    const savedPhoneOrEmail = localStorage.getItem("phoneOrEmail");
    return textFor("yourPhoneIs") + savedPhoneOrEmail;
  }

  function getTextToSendOtp() {
    const savedOtp = localStorage.getItem("otp");

    if (localStorage.getItem(USER_INPUT_TYPE) === "email") {
      return (
        <div>
          <p>{textFor("registrationStarted")}</p>
          <br />
          <div style={{ marginLeft: "5px" }}>
            <span>{textFor("sendOtpOnEmail1")}</span>
            <br />
            <span>{textFor("quicklyAfterRequest")}</span>
            <br />
            <span>{textFor("sendOtpOnEmail2")}</span>
            <br />
            <span>
              {textFor("sendOtpOnEmail3")}
              <strong>{"A " + savedOtp}</strong>
            </span>
            <br />
            <span>{textFor("sendOtpOnEmail4")}</span>
          </div>
          <p>{getTextForEmail()}</p>
          <p style={{ paddingRight: "15vw" }}>
            {textFor("activationWillTake24Hours")}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>{textFor("registrationStarted")}</p>
          <br />
          <div style={{ marginLeft: "5px" }}>
            <span>{textFor("sendOtpOnPhone1")}</span>
            <br />
            <span>{textFor("quicklyAfterRequest")}</span>
            <br />
            <span>{textFor("sendOtpOnPhone3")}</span>
            <br />
            <span>
              {textFor("sendOtpOnPhone4")}
              <strong>{"A " + savedOtp}</strong>
            </span>
          </div>
          <p>{getTextForPhone()}</p>
          <p style={{ paddingRight: "15vw" }}>
            {textFor("activationWillTake24Hours")}
          </p>
        </div>
      );
    }
  }

  function resetClicked(e) {
    if (window.confirm(textFor("areYouSure"))) {
      localStorage.removeItem("otp");
      localStorage.removeItem(USER_INPUT_TYPE);
      setIsOtpAvailable("");
      setOtp("");
      setPhoneOrEmail("");
      setPassword("");
    }
  }
  return (
    <div className={commonStyles.pageBackground}>
      {isSubmitting && <Loader />}
      <h2 className={commonStyles.pageTitle}>{textFor("registration")}</h2>
      {!isOtpAvailable && (
        <Form method="post">
          <br />
          <br />
          <div>
            <label className={commonStyles.fieldLabel} htmlFor="phoneOrEmail">
              {textFor("phoneOrEmail:")}
            </label>
            <br />
            <input
              className={commonStyles.inputField}
              type="text"
              id="phoneOrEmail"
              autoComplete="off"
              name="phoneOrEmail"
              placeholder="+910123456789 / a@b.com"
              value={phoneOrEmail}
              onChange={handlePhoneOrEmailChange}
            />

            <div style={{ marginTop: "1vh" }}>
              <label className={commonStyles.fieldLabel} htmlFor="password">
                {textFor("password:")}
              </label>
              <br />
              <input
                className={commonStyles.inputField}
                type="password"
                id="password"
                value={password}
                name="password"
                autoComplete="off"
                placeholder={textFor("passwordPlaceholder")}
                onChange={handlePasswordChange}
              />
            </div>

            <div style={{ marginTop: "1vh" }}>
              <label className={commonStyles.fieldLabel} htmlFor="otp">
                {textFor("otp")}
              </label>
              <br />
              <input
                className={commonStyles.inputField}
                type="number"
                id="otp"
                value={otp}
                autoComplete="off"
                maxLength="6"
                name="otp"
                placeholder={textFor("any6DigitNumber")}
                onChange={handleOtpChange}
              />
            </div>
          </div>
          <br />
          <button
            className={commonStyles.pageSubmitBtn}
            type="submit"
            disabled={isSubmitting}
          >
            {textFor("registerButton")}
          </button>
        </Form>
      )}
      <div>
        {isOtpAvailable && (
          <div>
            {getTextToSendOtp()}
            <button
              className={commonStyles.pageSubmitBtn}
              type="button"
              onClick={resetClicked}
            >
              {textFor("reset")}
            </button>
          </div>
        )}
      </div>

      <div className={commonStyles.bottomSection}>
        <p style={{ color: "white" }}>
          {textFor("alreadyHaveAnAccount?")}{" "}
          <Link style={{ color: "blue", marginLeft: "2vw" }} to="/login">
            {textFor("login")}
          </Link>
        </p>
      </div>
    </div>
  );
}
export default Register;

export async function action({ request, params }) {
  console.log("register action is called.. request = ", request);
  const data = await request.formData();
  let registrationData = {};
  const userInput = data.get("phoneOrEmail").trim();
  const otp = data.get("otp").trim();
  const password = data.get("password").trim();
  let url = BASE_URL + "/normalUser/";
  if (isAPhoneNumber(userInput)) {
    registrationData = {
      phone: userInput,
    };
    url += "registerWithPhone";
  } else if (isAnEmail(userInput)) {
    registrationData = {
      email: userInput,
    };
    url += "registerWithEmail";
  } else {
    return null;
    // throw json(
    //   { message: "Please input a valid phone or email" },
    //   { status: 500 }
    // );
  }
  registrationData.otp = otp;
  registrationData.password = password;

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(registrationData),
  });

  if (!response.ok) {
    throw json({ message: "Could not register." }, { status: 500 });
  }

  saveMaskedPhoneOrEmail(userInput);
  localStorage.setItem("otp", otp);

  return "";
}
