import AlertDialog from "../alertDialog/AlertDialog";
import styles from "./PersonDetailsDialog.module.scss";
import commonStyles from "../../../commonStyles.module.scss";
import CircularImage from "../CircularImage/CircularImage";
import userPlaceholderImageUrl from "../../../assets/userPlaceholder.png";
import educationOptionsJson from "../../../jsonConstants/educationTypes.json";
import yearlyIncomeOptionsJson from "../../../jsonConstants/yearlyIncome.json";
import maritalStatusOptionsJson from "../../../jsonConstants/maritalStatusTypes.json";
import weightRangeJson from "../../../jsonConstants/weightRange.json";
import heightFeetJson from "../../../jsonConstants/heightFeet.json";
import heightInchJson from "../../../jsonConstants/heightInch.json";
import { textFor } from "../../../util/languages";
import { getKundliDoshas } from "../../memberDetails/fieldValueUtils";

const { educationOptions } = educationOptionsJson;
const { yearlyIncomeOptions } = yearlyIncomeOptionsJson;
const { maritalStatusOptions } = maritalStatusOptionsJson;
const { weightRangeOptions } = weightRangeJson;
const { heightFeetOptions } = heightFeetJson;
const { heightInchOptions } = heightInchJson;
function PersonDetailsDialog({
  onClose,
  personDetails,
  getViewForTopRightArea,
}) {
  function showFieldIfAvailable(name, field) {
    if (field !== "" && field != null && field !== undefined) {
      //parse YES/NO dropdown field
      if (field === true) {
        //for field value = true for yes no dropdown flags
        field = "YES";
      } else if (field === false) {
        field = "NO";
      }

      return (
        <div>
          <b>{name}</b> {field}
        </div>
      );
    }
  }
  function getKundliDoshaStr() {
    let doshStr = "";
    const kundliDoshas = getKundliDoshas(personDetails);
    let i = 0;
    if (kundliDoshas.length > 0) {
      for (let dosh of kundliDoshas) {
        i++;
        doshStr += textFor(dosh);
        if (i < kundliDoshas.length) {
          doshStr += ", ";
        }
      }
    } else {
      doshStr = textFor("none");
    }
    return doshStr;
  }

  function getAge(birthYear) {
    if (birthYear) {
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear;
    } else {
      return "";
    }
  }

  function getHeight(feet, inches) {
    let retVal = "";
    if (feet) {
      const matchedRow = heightFeetOptions.filter((h) => h.id === feet);
      retVal = matchedRow[0].name;
      if (inches != null && inches != "" && inches.includes("INCH")) {
        const matchedRow = heightInchOptions.filter((h) => h.id === inches);
        retVal += ", " + matchedRow[0].name;
      }
    }
    return retVal;
  }
  function getWeight(weightStr) {
    let retVal = "";
    if (weightStr) {
      const matchedRow = weightRangeOptions.filter((w) => w.id === weightStr);
      retVal = matchedRow[0].name;
    }
    return retVal;
  }
  function getMaritalStatus(mStr) {
    let retVal = "";
    if (mStr) {
      const matchedRow = maritalStatusOptions.filter((m) => m.id === mStr);
      retVal = matchedRow[0].name;
    }

    return retVal;
  }
  function getYearlyIncome(yiStr) {
    let retVal = "";
    if (yiStr) {
      const matchedRow = yearlyIncomeOptions.filter((yi) => yi.id === yiStr);
      retVal = matchedRow[0].name;
    }

    return retVal;
  }
  function getEducation(eduStr) {
    let retVal = "";
    if (eduStr) {
      const matchedRow = educationOptions.filter((e) => e.id === eduStr);
      retVal = matchedRow[0].name;
    }
    return retVal;
  }

  console.log("got personDetails = ", personDetails);

  function getPersonDetailsView() {
    return (
      <div className={styles.detailsWrapper}>
        <div className={styles.topPanelWithImage}>
          <CircularImage
            profileImageUrl={
              personDetails.profileImageUrl || userPlaceholderImageUrl
            }
          />
          {getViewForTopRightArea()}
        </div>
        {showFieldIfAvailable(textFor("name") + ":", personDetails.fullName)}
        {showFieldIfAvailable(
          textFor("gender") + ":",
          personDetails.genderType
        )}
        {showFieldIfAvailable(
          textFor("age") + ":",
          getAge(personDetails.birthYear)
        )}
        {showFieldIfAvailable(textFor("gotra") + ":", personDetails.gotraType)}
        {showFieldIfAvailable(
          textFor("maritalStatus") + ":",
          getMaritalStatus(personDetails.maritalStatusType)
        )}
        <hr /> {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("education") + ":",
          getEducation(personDetails.educationLevelType)
        )}
        {showFieldIfAvailable(
          textFor("details") + ":",
          personDetails.educationDetails
        )}
        <hr /> {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("yearlyIncome") + ":",
          getYearlyIncome(personDetails.yearlyIncome)
        )}
        {showFieldIfAvailable(
          textFor("source") + ":",
          personDetails.incomeSourceDetails
        )}
        <hr /> {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("speciallyAbled") + ":",
          personDetails.isSpeciallyAbled
        )}
        {showFieldIfAvailable(
          textFor("specialAbilityDetails") + ":",
          personDetails.specialAbilityDetails
        )}
        {showFieldIfAvailable(
          textFor("height") + ":",
          getHeight(
            personDetails.heightFeetType,
            personDetails.heightInchesType
          )
        )}
        {showFieldIfAvailable(
          textFor("weight") + ":",
          getWeight(personDetails.weightRangeType)
        )}
        {showFieldIfAvailable(
          textFor("pureVeg") + ":",
          personDetails.isPureVegetarian
        )}
        {showFieldIfAvailable(
          textFor("kundliDosha") + ":",
          getKundliDoshaStr()
        )}
        {showFieldIfAvailable(
          textFor("otherDetails") + ":",
          personDetails.otherDetails
        )}
        <hr /> {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("relation") + ":",
          personDetails.familyRelationType
        )}
        {showFieldIfAvailable(
          textFor("guardian") + ":",
          personDetails.guardianName
        )}
        {showFieldIfAvailable(
          textFor("state") + ":",
          personDetails.addressStateName
        )}
        {showFieldIfAvailable(
          textFor("city") + ":",
          personDetails.addressCityName
        )}
        {showFieldIfAvailable(
          textFor("address") + ":",
          personDetails.detailedAddress
        )}
        <hr /> {/*--------------------------------------------------------*/}
      </div>
    );
  }
  return (
    <AlertDialog
      onOkClick={onClose}
      onBackdropClicked={onClose}
      title={textFor("personDetails:")}
      getContent={getPersonDetailsView}
    />
  );
}
export default PersonDetailsDialog;
