import Dropdown from "../../UI/dropdown/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyles from "../../../commonStyles.module.scss";
import { useEffect, useState } from "react";
import { doNavigate } from "../../../util/auth";
import {
  AGE,
  DISTANCE_RANGE,
  GENDER,
  GOTRA,
  MAX_AGE,
  MIN_AGE,
  NAME,
  OPTIONAL_FILTERS,
  SEARCH_PEOPLE_CRITERIA,
  SPECIAL_ABILITY,
} from "../../../Constants";
import {
  getAgeFilterView,
  getDistanceFilterView,
  getGenderFilterView,
  getGotraFilterView,
  getNameFilterView,
  getSpeciallyAbledFilterView,
} from "../../../pages/searchGroom/SearchFilters";
import AlertDialog from "../../UI/alertDialog/AlertDialog";
import { useDispatch } from "react-redux";
import { clearedSearchedPersons } from "../../../store/reducers/friendsSlice";
import { areSomeFiltersEmpty } from "../../../util/filterUtil";
import { textFor } from "../../../util/languages";
import { saveActiveRoute } from "../../../util/viewUtils";
function SearchPeopleCriteria(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeRoute = useLocation();
  saveActiveRoute(activeRoute);
  const initialSortedFilterTypes = [
    { id: NAME, name: textFor("name") },
    { id: DISTANCE_RANGE, name: textFor("distanceRange") },
    { id: GOTRA, name: textFor("gotra") },
    { id: SPECIAL_ABILITY, name: textFor("specialAbility") },
    { id: AGE, name: textFor("age") },
    { id: GENDER, name: textFor("gender") },
  ];
  const [availableFilterTyepes, setAvailableFilterTypes] = useState(
    initialSortedFilterTypes
  );
  const initialFilterData = {};
  initialFilterData[NAME] = "";
  initialFilterData[DISTANCE_RANGE] = "";
  initialFilterData[GOTRA] = "";
  initialFilterData[SPECIAL_ABILITY] = "";
  initialFilterData[MIN_AGE] = "";
  initialFilterData[MAX_AGE] = "";
  initialFilterData[GENDER] = "";

  const [valueForAddFilterDropdown, setValueForAddFilterDropdown] =
    useState("");
  const [alertDialogFields, setAlertDialogFields] = useState({
    shouldBeVisible: false,
    title: "",
    message: "",
  });
  const [formData, setFormData] = useState(initialFilterData);
  const [optionalFilters, setOptionalFilters] = useState([]);
  // const location = useLocation();
  // console.log("location is ", location);
  let filtersUsedInCriteria = {};
  useEffect(() => {
    const savedOptionalFilters = localStorage.getItem(OPTIONAL_FILTERS);
    if (savedOptionalFilters !== null) {
      const filters = JSON.parse(savedOptionalFilters);
      //re-add previously added filters
      setOptionalFilters((prevState) => filters);

      const remainingFilterTypes = availableFilterTyepes.filter(
        (item) => !filters.includes(item.id)
      );
      setAvailableFilterTypes([...remainingFilterTypes]);
      const criteria = localStorage.getItem(SEARCH_PEOPLE_CRITERIA) || "[]";
      const filterValues = JSON.parse(criteria);
      Object.keys(filterValues).map((k) => {
        setFilterValue(k, filterValues[k]);
        return k;
      });
      dispatch(clearedSearchedPersons(null, null));
      // localStorage.removeItem(OPTIONAL_FILTERS);
      // localStorage.removeItem(SEARCH_PEOPLE_CRITERIA);
    }
  }, []);
  function addSelectedFilter(e) {
    console.log("selectedFilter = ", e.target.value);
    //for small amount of time set it
    // NO NEED of this setValueForAddFilterDropdown(e.target.value);
    const filterName = e.target.value;
    setOptionalFilters((prevFilters) => [...prevFilters, filterName]);
    const remainingFilterTypes = availableFilterTyepes.filter(
      (item) => item.id !== e.target.value
    );
    setAvailableFilterTypes([...remainingFilterTypes]);
    //The add Filter dropdown should always show blank
    setValueForAddFilterDropdown("");
  }

  function setAlertDialogVisibility(visibilityFlag) {
    setAlertDialogFields((prevState) => {
      const newState = { ...prevState, shouldBeVisible: visibilityFlag };
      return { ...newState };
    });
  }
  function closeAlert(e) {
    setAlertDialogVisibility(false);
  }
  function setFilterValue(filterName, value) {
    console.log("filterName  = ", filterName);
    setFormData((prevState) => ({ ...prevState, [filterName]: value }));
  }
  function isNoFilterChosen() {
    return optionalFilters.length < 1;
  }
  function showAlertThatNoFilterIsChosen() {
    setAlertDialogFields((prevState) => {
      return {
        shouldBeVisible: true,
        title: textFor("noFilterSelected"),
        message: textFor("pleaseAddAtLeastOneFilter"),
      };
    });
  }

  function showDialogToSelectValuesFromAllFilters(mesg) {
    setAlertDialogFields((prevState) => {
      return {
        shouldBeVisible: true,
        title: textFor("emptyFilters"),
        message: mesg,
      };
    });
  }
  function alertDisplayedForName() {
    return false;
  }

  function noAlertShownDuringValidtion() {
    //return true if all validations are ok
    //else show alert and return false
    if (alertDisplayedForName()) {
      return false;
    }
    return true;
  }
  function searchPeopleWithFilterAndShowResults() {
    //if data is not found then using the same searchCriteria
    //from localStorage we will show the added filters.
    doNavigate(navigate, "/searchedPeople");
  }

  function onSearchClick(e) {
    console.log("optionalFilters = ", optionalFilters);
    console.log("formData = ", formData);
    if (isNoFilterChosen()) {
      showAlertThatNoFilterIsChosen();
    } else {
      let incompleteFilterMsg = { value: "" };
      //this will also fill in incompleteFilterMsg
      if (
        areSomeFiltersEmpty(
          optionalFilters,
          formData,
          SEARCH_PEOPLE_CRITERIA,
          incompleteFilterMsg
        )
      ) {
        showDialogToSelectValuesFromAllFilters(incompleteFilterMsg.value);
      } else {
        if (noAlertShownDuringValidtion()) {
          searchPeopleWithFilterAndShowResults();
        }
      }
    }
  }
  function removeFieldFromCriteria(idStr) {
    console.log("removing field: ", idStr);
    setOptionalFilters((prevState) => {
      const updatedOptionalFilters = prevState.filter((item) => item !== idStr);
      return [...updatedOptionalFilters];
    });
    //put this id to remove in Min Edu idArray
    let idArray = [idStr];

    //append other filts Ids into that idArray
    availableFilterTyepes.map((f) => {
      idArray.push(f.id);
      return f;
    });
    //now idArray has all the ids of filters to show
    //just show them in sorted order, so create a
    //sortedFilters array having the fields in sorted order
    let sortedFilters = [];
    initialSortedFilterTypes.map((fObj) => {
      if (idArray.includes(fObj.id)) {
        sortedFilters.push(fObj);
      }
      return fObj;
    });
    setAvailableFilterTypes([...sortedFilters]);
  }
  function getDistanceRangeFilter() {
    return getDistanceFilterView(
      DISTANCE_RANGE,
      (e) => removeFieldFromCriteria(DISTANCE_RANGE),
      formData,
      setFilterValue
    );
  }
  function getGotraFilter() {
    return getGotraFilterView(
      GOTRA,
      (e) => removeFieldFromCriteria(GOTRA),
      formData,
      setFilterValue
    );
  }
  function getSpecialAbilityFilter() {
    return getSpeciallyAbledFilterView(
      SPECIAL_ABILITY,
      (e) => removeFieldFromCriteria(SPECIAL_ABILITY),
      formData,
      setFilterValue
    );
  }
  function getNameFilter() {
    return getNameFilterView(
      NAME,
      (e) => removeFieldFromCriteria(NAME),
      formData,
      setFilterValue
    );
  }
  function getAgeFilter() {
    return getAgeFilterView(
      AGE,
      (e) => removeFieldFromCriteria(AGE),
      formData,
      setFilterValue
    );
  }
  function getGenderFilter() {
    return getGenderFilterView(
      GENDER,
      (e) => removeFieldFromCriteria(GENDER),
      formData,
      setFilterValue
    );
  }
  function getFilterOfType(type) {
    let filter = "";
    switch (type) {
      case DISTANCE_RANGE:
        filter = getDistanceRangeFilter();
        break;
      case GOTRA:
        filter = getGotraFilter();
        break;
      case SPECIAL_ABILITY:
        filter = getSpecialAbilityFilter();
        break;
      case NAME:
        filter = getNameFilter();
        break;
      case AGE:
        filter = getAgeFilter();
        break;
      case GENDER:
        filter = getGenderFilter();
        break;
      default:
        console.log("UNHANDLED TYPE: ", type);
        break;
    }
    return filter;
  }
  function getOptionalFilters() {
    return (
      <>
        {optionalFilters.map((f, index) => {
          // console.log("got value ", f);
          return (
            <div className={commonStyles.optionalFilterStyle} key={index}>
              {getFilterOfType(f)}
            </div>
          );
        })}
      </>
    );
  }
  function getDropdownToAddAvailableFilters() {
    if (availableFilterTyepes.length > 0) {
      return (
        <div style={{ margin: "15px 5px 0 5px" }}>
          <Dropdown
            name="addFilter"
            message={textFor("addFilter")}
            label=""
            value={valueForAddFilterDropdown}
            onChange={addSelectedFilter}
            options={availableFilterTyepes}
            disabled={false}
          />
        </div>
      );
    }
  }
  function getSearchCriteriaView() {
    return (
      <>
        {alertDialogFields.shouldBeVisible ? (
          <AlertDialog
            onOkClick={closeAlert}
            onBackdropClicked={(e) => {}}
            title={alertDialogFields.title}
            getContent={() => (
              <p id="dialogMesg">{alertDialogFields.message}</p>
            )}
          />
        ) : (
          <>
            <div
              className={commonStyles.topPanel}
              style={{ color: "black", fontWeight: "bold" }}
            >
              {textFor("searchCriteria")}
            </div>
            <div className={commonStyles.scrollableContainer}>
              {getOptionalFilters()}
              {optionalFilters.length > 0 && (
                <div style={{ color: "black" }}>
                  <br /> <br />
                </div>
              )}
              {getDropdownToAddAvailableFilters()}
              <button
                id="peopleSearchButton"
                className={commonStyles.blueButton}
                style={{ width: "7em", marginLeft: "5px" }}
                type="button"
                onClick={onSearchClick}
              >
                {textFor("search")}
              </button>
            </div>
          </>
        )}
      </>
    );
  }

  return <>{getSearchCriteriaView()}</>;
}

export default SearchPeopleCriteria;
