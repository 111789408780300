import EditableFieldWithOtp from "./editableEmail/EditableFieldWithOtp";
import PendingEmail from "./pendingEmail/PendingEmail";
import { BASE_URL } from "../../../../Constants";
import { doLogout, getAuthToken } from "../../../../util/auth";
import { useDispatch, useSelector } from "react-redux";
import { setPendingEmail } from "../../../../store/reducers/pendingEmailSlice";
import { useEffect, useState } from "react";
import { authActions } from "../../../../store/reducers/authSlice";
import { Loader } from "../../../UI/ErrorModal";
import { json } from "react-router-dom";
import { textFor } from "../../../../util/languages";
import { isAnEmail } from "../../../../util/fieldChecker";
function EmailField(props) {
  console.log("props in EmailField = ", props);
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const pendingEmail = useSelector((state) => state.pendingEmail.pendingEmail);
  console.log("pendingEmail from redux = ", pendingEmail);
  const updateNewEmail = (newDetails) => {
    console.log("newDetails are ", newDetails);
    if (newDetails.userInput !== "" && newDetails.otp !== "") {
      apiCall_setNewPendingEmail(newDetails, dispatch);
    }
  };
  useEffect(() => {
    if ("pendingEmail" in props.registrationDetails !== "") {
      dispatch(
        setPendingEmail({
          pendingEmail: props.registrationDetails.pendingEmail,
          pendingEmailOtp: props.registrationDetails.pendingEmailOtp,
        })
      );
    }
  }, [dispatch, props.registrationDetails]);
  const apiCall_setNewPendingEmail = async (newDetails, dispatch) => {
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updateEmail";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          email: newDetails.userInput,
          otp: newDetails.otp,
        }),
      });
      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          throw json(
            {
              message: "Could not update password.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        window.alert(textFor("yourEmailChangeRequestIsAccepted"));
        dispatch(
          setPendingEmail({
            pendingEmail: newDetails.userInput,
            pendingEmailOtp: newDetails.otp,
          })
        );
      }
      console.log("response is = ", response);
    } catch (error) {
      console.error("Error during PATCH request:", error);
    } finally {
      console.log("end loading here");
      setSubmitting(false);
    }
  };
  return (
    <>
      {isSubmitting && <Loader />}
      <label>Email:</label>
      {/* Don't include the component in the label, else it will do multiple click
      and won't show the dialog to input new email */}
      <EditableFieldWithOtp
        initialFieldValue={props.registrationDetails.email}
        updateFieldValue={updateNewEmail}
        placeholder={textFor("typeNewEmail")}
        heading={textFor("detailsForNewEmail")}
        userInputTitle={textFor("email")}
        userInputValidator={isAnEmail}
      />
      {!pendingEmail || pendingEmail === "" ? "" : <PendingEmail />}
    </>
  );
}
export default EmailField;
