import { USER_INPUT_TYPE } from "../Constants";
import { isAnEmail, isAPhoneNumber } from "./fieldChecker";

export function scrollTabToMakeFullyVisible(event) {
  const target = event.target.closest("button");
  const parentContainer = target.closest("button").closest("div"); // Get the parent container

  if (parentContainer) {
    const elementRect = target.getBoundingClientRect();
    const parentRect = parentContainer.getBoundingClientRect();

    // Calculate the amount to scroll
    let scrollAmount;
    if (elementRect.left < parentRect.left) {
      // Element is hidden on the left side
      scrollAmount = elementRect.left - parentRect.left;
      parentContainer.scrollTo({
        left: parentContainer.scrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth scrolling if supported
      });
    } else if (elementRect.right > parentRect.right) {
      // Element is hidden on the right side
      scrollAmount = elementRect.right - parentRect.right;
      parentContainer.scrollTo({
        left: parentContainer.scrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth scrolling if supported
      });
    } else {
      // Element is fully visible, no need to scroll.
      return;
    }
  }
}

export function getAge(m) {
  if (m.birthYear) {
    const currentYear = new Date().getFullYear();
    return currentYear - m.birthYear;
  }
  return "X";
}
export function getCurrentDateTimeFormatted() {
  const now = new Date();
  const istOffset = 5 * 60 + 30; // Offset in minutes
  const istTime = new Date(now.getTime() + istOffset * 60 * 1000);
  const formattedTime = istTime.toISOString().slice(0, 19).replace("T", " ");

  console.log("returning time = ", formattedTime);
  return formattedTime;
}
export function getCurrentDateTimeFormatted1() {
  const currentTime = new Date();
  const formattedDateTime = currentTime.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  const hyphenSeparatedStr = formattedDateTime.split("/").join("-");
  console.log("returning time:", hyphenSeparatedStr);
  return hyphenSeparatedStr;
}
export function saveActiveRoute(activeRoute) {
  localStorage.setItem("activeRoute", activeRoute.pathname);
}
export function isAddressCityMissingInProfile() {
  const reduxUserProfile = JSON.parse(
    localStorage.getItem("reduxState_userProfile")
  );
  const shouldShowAlert =
    !Object.keys(reduxUserProfile).includes("addressCityName") ||
    reduxUserProfile.addressCityName === "";
  // if (shouldShowAlert) {
  //   window.alert(textFor("addressIsMissing"));
  // }
  return shouldShowAlert;
}

export function getMaskedEmail(email) {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart =
    localPart.length > 4
      ? localPart.slice(0, 4) + "*".repeat(localPart.length - 4)
      : localPart;

  const domainParts = domain.split(".");
  const lastPart = domainParts.pop();
  const maskedDomain = "*".repeat(domainParts.pop().length);

  const maskedEmail = `${maskedLocalPart}@${maskedDomain}.${lastPart}`;
  return maskedEmail;
}

export function getMaskedPhone(phone) {
  const lastFour = phone.slice(-4);
  const maskedNumber = "*".repeat(9) + lastFour;
  return maskedNumber;
}

export function saveMaskedPhoneOrEmail(userInput) {
  if (isAPhoneNumber(userInput)) {
    localStorage.setItem(USER_INPUT_TYPE, "phone");
    localStorage.setItem("phoneOrEmail", getMaskedPhone(userInput));
  } else if (isAnEmail(userInput)) {
    localStorage.setItem(USER_INPUT_TYPE, "email");
    localStorage.setItem("phoneOrEmail", getMaskedEmail(userInput));
  }
}

export function startsWith(str, searchString) {
  // If the search string is longer than the main string, it can't be a prefix
  if (searchString.length > str.length) {
    return false;
  }

  // Compare characters one by one
  for (let i = 0; i < searchString.length; i++) {
    if (str[i] !== searchString[i]) {
      return false;
    }
  }

  return true;
}
