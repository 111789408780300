import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa"; // Importing the pencil icon from react-icons library
// import { CiCoffeeCup } from "react-icons/ci";
import styles from "./EditableField.module.scss";
import ModalWithOtp from "../../../../UI/modal/ModalWithOtp";

const EditableFieldWithOtp = ({
  placeholder,
  heading,
  initialFieldValue,
  updateFieldValue,
  userInputTitle,
  userInputValidator,
}) => {
  const [isEditing, setEditing] = useState(false);

  const handleEditClick = () => {
    setEditing(true);
    console.log("edit clicked...");
  };

  const handleSaveClick = (newFeildValue) => {
    console.log("save clicked: newFeildValue=", newFeildValue);
    setEditing(false);
    updateFieldValue(newFeildValue);
  };

  const handleCancelClick = (event) => {
    console.log("cancel clicked ", event);
    setEditing(false);
  };

  return (
    <>
      <div className={styles.readOnlyEmailContainer}>
        <div className={styles.readOnlyEmailField}>{initialFieldValue}</div>
        <div className={styles.editIcon} onClick={handleEditClick}>
          <FaPencilAlt />
        </div>
      </div>
      {isEditing ? (
        <ModalWithOtp
          onCancel={handleCancelClick}
          onOk={handleSaveClick}
          placeholder={placeholder}
          modalHeading={heading}
          userInputTitle={userInputTitle}
          userInputValidator={userInputValidator}
        ></ModalWithOtp>
      ) : (
        ""
      )}
    </>
  );
};

export default EditableFieldWithOtp;
