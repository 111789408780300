import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./ImageCropper.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { textFor } from "../../util/languages";

function ImageCropper({
  imageUrl,
  setProfileImage,
  goBack,
  setIsDefaultImageUsed,
}) {
  const imageRef = useRef(null);
  const initialCropBoundary = {
    x: 20,
    y: 5,
    width: 100,
    height: 100,
    unit: "px",
  };
  const [crop, setCrop] = useState(initialCropBoundary);
  const [croppedImage, setCroppedImage] = useState(imageUrl);

  const getCroppedImage = ({ setProfileImage }) => {
    if (
      imageRef.current &&
      crop.width &&
      crop.height &&
      crop.x !== undefined &&
      crop.y !== undefined
    ) {
      console.log("imageRef=", imageRef, "crop=", crop);
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      const ctx = canvas.getContext("2d");

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.drawImage(
        imageRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        setCroppedImage(URL.createObjectURL(blob));
      }, "image/png");
    }
  };

  // useEffect(() => {
  //   getCroppedImage(initialCropBoundary);
  //   setTimeout(() => {
  //     getCroppedImage(initialCropBoundary);
  //   }, 100); //100 ms delay
  // }, [imageUrl]);
  function okClicked(e) {
    setProfileImage(croppedImage);
    setIsDefaultImageUsed(false);
    goBack(true);
  }
  return (
    <div>
      <hr />
      <div style={{ color: "black", fontWeight: "bold" }}>
        {textFor("adjustSelectionToCrop")}
      </div>
      <div className={styles.originalImageContainer}>
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(crop) => getCroppedImage(crop)}
          keepSelection={true}
        >
          <img
            className={styles.originalImage}
            src={imageUrl}
            ref={imageRef}
            alt="User Pic"
            onLoad={() => {
              getCroppedImage(initialCropBoundary);
            }}
          />
        </ReactCrop>
      </div>
      <br />
      <hr />
      <div style={{ color: "black", fontWeight: "bold" }}>
        {textFor("profilePreview:")}
      </div>
      <div className={styles.profilePreviewContainer}>
        <img
          id="croppedProfilePicPreview"
          className={styles.profilePreview}
          src={croppedImage}
          alt="Cropped Img"
        />
        <button
          id="captureOkButton"
          className={commonStyles.greenButton}
          onClick={okClicked}
        >
          Ok
        </button>
      </div>
      <hr />
    </div>
  );
}

export default ImageCropper;
