import React, { useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import styles from "./Modal.module.scss";
import { textFor } from "../../../util/languages";

const ModalWithOtp = (props) => {
  const inputFieldRef = useRef(null);
  const inputFieldOtpRef = useRef(null);
  const [otp, setOtp] = useState("");
  const onOk = (e) => {
    if (props.userInputValidator(inputFieldRef.current.value)) {
      if (otp.length !== 6) {
        alert(textFor("otpMustBeOf6Digits")); //todo use modal here so that the click hander will not take too much time
      } else {
        props.onOk({
          userInput: inputFieldRef.current.value,
          otp: inputFieldOtpRef.current.value,
        });
      }
    }
  };
  function onOtpChanged(e) {
    if (e.target.value.length <= 6) {
      setOtp(e.target.value);
    }
  }
  return (
    <div className={`${styles.modal} ${styles.open}`}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>{props.modalHeading}</h2>
          <button className={styles.closeBtn} onClick={props.onCancel}>
            <FaTimes />
          </button>
        </div>
        <div className={styles.modalBody}>
          <label htmlFor="inputField">{props.userInputTitle}</label>
          <input
            type="text"
            id="inputField"
            ref={inputFieldRef}
            placeholder={props.placeholder || ""}
          />
          <br />
          <br />
          <label htmlFor="inputFieldOtp">OTP</label>
          <input
            type="text"
            id="inputFieldOtp"
            ref={inputFieldOtpRef}
            onChange={onOtpChanged}
            placeholder={textFor("any6DigitNumber")}
            value={otp}
          />
        </div>
        <div className={styles.modalFooter}>
          <button onClick={onOk}>{props.okButtonText || "OK"}</button>
          <button onClick={props.onCancel}>
            {props.cancelButtonText || "CANCEL"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalWithOtp;
