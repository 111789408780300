import EditableFieldWithOtp from "./editableEmail/EditableFieldWithOtp";
import PendingPhone from "./pendingPhone/PendingPhone";
import { BASE_URL } from "../../../../Constants";
import { doLogout, getAuthToken } from "../../../../util/auth";
import { useDispatch, useSelector } from "react-redux";
import { setPendingPhone } from "../../../../store/reducers/pendingPhoneSlice";
import { useEffect, useState } from "react";
import { authActions } from "../../../../store/reducers/authSlice";
import { Loader } from "../../../UI/ErrorModal";
import { json } from "react-router-dom";
import { textFor } from "../../../../util/languages";
import { isAPhoneNumber } from "../../../../util/fieldChecker";
function PhoneField(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  console.log("props in PhoneField = ", props);
  const dispatch = useDispatch();
  const pendingPhone = useSelector((state) => state.pendingPhone.pendingPhone);
  console.log("pendingPhone from redux = ", pendingPhone);
  const updateNewPhone = (newDetails) => {
    console.log("newDetails are ", newDetails);
    if (newDetails.userInput !== "" && newDetails.otp !== "") {
      apiCall_setNewPendingPhone(newDetails, dispatch);
    }
  };
  useEffect(() => {
    if ("pendingPhone" in props.registrationDetails !== "") {
      dispatch(
        setPendingPhone({
          pendingPhone: props.registrationDetails.pendingPhone,
          pendingPhoneOtp: props.registrationDetails.pendingPhoneOtp,
        })
      );
    }
  }, [dispatch, props.registrationDetails]);
  const apiCall_setNewPendingPhone = async (newDetails, dispatch) => {
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updatePhone";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          phone: newDetails.userInput,
          otp: newDetails.otp,
        }),
      });
      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          throw json(
            {
              message: "Could not update phone.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        window.alert(textFor("yourPhoneChangeRequestIsAccepted"));
        dispatch(
          setPendingPhone({
            pendingPhone: newDetails.userInput,
            pendingPhoneOtp: newDetails.otp,
          })
        );
      }
      console.log("response is = ", response);
    } catch (error) {
      console.error("Error during PATCH request:", error);
    } finally {
      console.log("end loading here");
      setSubmitting(false);
    }
  };
  return (
    <>
      {isSubmitting && <Loader />}
      <label>Phone:</label>
      {/* Don't include the component in the label, else it will do multiple click
      and won't show the dialog to input new email */}
      <EditableFieldWithOtp
        initialFieldValue={props.registrationDetails.phone}
        updateFieldValue={updateNewPhone}
        placeholder={textFor("typeNewPhone")}
        heading={textFor("detailsForNewPhone")}
        userInputTitle={textFor("phone")}
        userInputValidator={isAPhoneNumber}
      />
      {!pendingPhone || pendingPhone === "" ? "" : <PendingPhone />}
    </>
  );
}
export default PhoneField;
