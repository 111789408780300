import React, { useState } from "react";
import styles from "./MemberDetails.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import Dropdown from "../UI/dropdown/Dropdown";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import birthYearJson from "../../jsonConstants/birthYear.json";
import educationOptionsJson from "../../jsonConstants/educationTypes.json";
import genderTypeOptionsJson from "../../jsonConstants/genderTypes.json";
import gotraTypesJson from "../../jsonConstants/gotraTypes.json";
import heightFeetJson from "../../jsonConstants/heightFeet.json";
import heightInchJson from "../../jsonConstants/heightInch.json";
import kundliDoshaTypesJson from "../../jsonConstants/kundliDosha.json";
import maritalStatusOptionsJson from "../../jsonConstants/maritalStatusTypes.json";
import relationTypeOptionsJson from "../../jsonConstants/relationTypes.json";
import weightRangeJson from "../../jsonConstants/weightRange.json";
import yesNoOptionsJson from "../../jsonConstants/yesNo.json";
import yearlyIncomeOptionsJson from "../../jsonConstants/yearlyIncome.json";
import CircularImage from "../UI/CircularImage/CircularImage";
import { useLocation } from "react-router-dom";
import { textFor } from "../../util/languages";
import MultiColMultiChoiceView from "./MultiColMultiChoiceView";
import Button from "../UI/Button";

const { birthYearOptions } = birthYearJson;
const { educationOptions } = educationOptionsJson;
const { genderTypeOptions } = genderTypeOptionsJson;
const { gotraTypeOptions } = gotraTypesJson;
const { heightFeetOptions } = heightFeetJson;
const { heightInchOptions } = heightInchJson;
const { kundliDoshaTypeOptions } = kundliDoshaTypesJson;
const { maritalStatusOptions } = maritalStatusOptionsJson;
const { relationTypeOptions } = relationTypeOptionsJson;
const { weightRangeOptions } = weightRangeJson;
const { yearlyIncomeOptions } = yearlyIncomeOptionsJson;
const { yesNoOptions } = yesNoOptionsJson;

const EditableMemberDetailsView = ({
  formData,
  handleChange,
  resetFormFields,
  clearFormFields,
  handleFormSubmit,
  setIsDefaultImageUsed,
  setProfileImage,
  setIsEditing,
  setEnableCaptureImage,
}) => {
  console.log("formData in View:", formData);
  const activeRoute = useLocation();
  const [isKundliDoshMultiChoiceVisibe, setKundliDoshMultiChoiceVisibility] =
    useState("");
  const [kundliDoshas, setKundliDoshas] = useState(formData.kundliDoshas);
  const useDefaultImageClicked = () => {
    setIsDefaultImageUsed(true);
    setProfileImage(userPlaceholderImageUrl);
  };
  function proceedToSubmitForm(event) {
    //todo why this special handling of kundliDosha ??
    formData.kundliDoshas = kundliDoshas;
    handleFormSubmit(event);
  }
  // const handleGoBack = (e) => {
  //   doNavigate(navigate, "/familyMembers");
  // };
  const handleEditClick = (e) => {
    setIsEditing(true);
    console.log("activeRoute=", activeRoute.pathname);
  };

  const handleCancelClick = (e) => {
    resetFormFields();
  };

  const handleClearClick = () => {
    clearFormFields();
  };

  const getNameBirthGenderGotraView = () => {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("name")}</div>
          <input
            name="fullName"
            type="text"
            className={styles.inputField}
            placeholder={textFor("fullName")}
            disabled={!formData.isEditing}
            value={formData.fullName}
            onChange={handleChange}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("birthYear")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="birthYear"
              message={textFor("selectBirthYear")}
              label=""
              value={formData.birthYear}
              onChange={handleChange}
              options={birthYearOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("gender")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="genderType"
              message={textFor("selectGender")}
              label=""
              value={formData.genderType || ""}
              onChange={handleChange}
              options={genderTypeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("gotra")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="gotraType"
              message={textFor("chooseGotra")}
              label=""
              value={formData.gotraType}
              onChange={handleChange}
              options={gotraTypeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
      </>
    );
  };
  function getYesNoValueForDropdown(fieldValue) {
    if (fieldValue === null || fieldValue === "") {
      return "";
    }
    if (fieldValue === false || fieldValue === "NO") {
      return "NO";
    } else {
      return "YES";
    }
  }
  function getFoodMarriageEducationView() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("pureVeg")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="isPureVegetarian"
              message={textFor("choose")}
              label=""
              value={getYesNoValueForDropdown(formData.isPureVegetarian)}
              onChange={handleChange}
              options={yesNoOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("maritalStatus")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="maritalStatusType"
              message={textFor("choose")}
              label=""
              value={formData.maritalStatusType || ""}
              onChange={handleChange}
              options={maritalStatusOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("education")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="educationLevelType"
              message={textFor("choose")}
              label=""
              value={formData.educationLevelType || ""}
              onChange={handleChange}
              options={educationOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>

        {formData.showEduDetails ? (
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldLabelMultiline}>
              <div>{textFor("education")}</div>
              <div>{textFor("details")}</div>
            </div>
            <textarea
              name="educationDetails"
              rows={3}
              className={styles.inputField}
              placeholder={textFor("eduDetailsPlaceholder")}
              disabled={!formData.isEditing}
              value={formData.educationDetails}
              onChange={handleChange}
            ></textarea>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  function getTextStyleForEditOrNormal() {
    if (formData.isEditing) {
      return { color: "black" };
    } else {
      return { color: "grey" };
    }
  }

  function getDoshaSelectedMessage() {
    if (kundliDoshas.length > 0) {
      if (kundliDoshas.length === 1) {
        return textFor(kundliDoshas.at(0));
      } else {
        return textFor(kundliDoshas.at(0)) + "  +" + (kundliDoshas.length - 1);
      }
    } else {
      return textFor("noKundliDosh");
    }
  }

  function getBodyIncomeOtherFieldsView() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("weight")}</div>*
          <div className={styles.dropdown}>
            <Dropdown
              name="weightRangeType"
              message={textFor("weightRange")}
              label=""
              value={formData.weightRangeType || ""}
              onChange={handleChange}
              options={weightRangeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("height")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="heightFeetType"
              message={textFor("feet")}
              label=""
              value={formData.heightFeetType || ""}
              onChange={handleChange}
              options={heightFeetOptions}
              disabled={!formData.isEditing}
            />
          </div>
          {/* <div className={styles.fieldLabel}>Feet, </div> */}
          <div className={styles.dropdown}>
            <Dropdown
              name="heightInchesType"
              message={textFor("inches")}
              label=""
              value={formData.heightInchesType || ""}
              onChange={handleChange}
              options={heightInchOptions}
              disabled={!formData.isEditing}
            />
          </div>
          {/* <div className={styles.fieldLabel}>Inches</div> */}
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("specialAbility")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="isSpeciallyAbled"
              message={textFor("choose")}
              label=""
              value={getYesNoValueForDropdown(formData.isSpeciallyAbled)}
              onChange={handleChange}
              options={yesNoOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
        {formData.showChallengeDetails ? (
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldLabelMultiline}>
              <div>{textFor("specialAbility")}</div>
              <div>{textFor("details")}</div>
            </div>
            <textarea
              name="specialAbilityDetails"
              rows={3}
              className={styles.inputField}
              placeholder={textFor("optionalDetailsAboutSpecialAbility")}
              disabled={!formData.isEditing}
              value={formData.specialAbilityDetails}
              onChange={handleChange}
            ></textarea>
          </div>
        ) : (
          ""
        )}
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("yearlyIncome")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="yearlyIncome"
              message={textFor("choose")}
              label=""
              value={formData.yearlyIncome || ""}
              onChange={handleChange}
              options={yearlyIncomeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabelMultiline}>
            <div>{textFor("incomeSource")}</div>
            <div>{textFor("details")}</div>
          </div>
          <textarea
            name="incomeSourceDetails"
            rows={3}
            className={styles.inputField}
            placeholder={textFor("optionalIncomeSourceDetails")}
            disabled={!formData.isEditing}
            value={formData.incomeSourceDetails || ""}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("kundliDosha:")}</div>
          <div
            className={commonStyles.boxWithBorder}
            disabled={!formData.isEditing}
          >
            <div
              id="doshaDetails"
              disabled={!formData.isEditing}
              style={getTextStyleForEditOrNormal()}
            >
              {getDoshaSelectedMessage()}
            </div>
            <button
              id="kundliDoshChooserButton"
              onClick={() => setKundliDoshMultiChoiceVisibility("1")}
              type="button"
              disabled={!formData.isEditing}
            >
              {textFor("choose")}
            </button>
          </div>
          {isKundliDoshMultiChoiceVisibe && (
            <MultiColMultiChoiceView
              title={textFor("selectKundliDoshas:")}
              onClose={() => {
                setKundliDoshMultiChoiceVisibility("");
              }}
              options={kundliDoshaTypeOptions}
              selectedItems={kundliDoshas}
              onChange={setKundliDoshas}
            />
          )}
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabelMultiline}>
            <div>{textFor("anyOther")}</div>
            <div>{textFor("details")}</div>
          </div>
          <textarea
            name="otherDetails"
            rows={3}
            className={styles.inputField}
            placeholder={textFor("optionalDetailsAboutAnyOtherThing")}
            disabled={!formData.isEditing}
            value={formData.otherDetails || ""}
            onChange={handleChange}
          ></textarea>
        </div>
      </>
    );
  }
  function getFormData() {
    return { filterName: "" };
  }
  function getRelationshipTypeView() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabel}>{textFor("relation")}</div>
          <div className={styles.dropdown}>
            <Dropdown
              name="familyRelationType"
              message={textFor("chooseRelationWithYou")}
              label=""
              value={formData.familyRelationType}
              onChange={handleChange}
              options={relationTypeOptions}
              disabled={!formData.isEditing}
            />
          </div>
        </div>
      </>
    );
  }
  function isProfileScreen() {
    return activeRoute.pathname.includes("profile");
  }
  function getTopPanelstyle() {
    if (!isProfileScreen()) {
      return commonStyles.topPanel;
    } else {
      /* For profile screen, we show it in the tabs, so in top panel
    hide the title and keep the edit button on right side.
    */
      return `${commonStyles.topPanel} ${commonStyles.rightAligned} ${commonStyles.transparentBackground}`;
    }
  }
  function getImageAndChangeButtonsView() {
    return (
      <>
        <div className={getTopPanelstyle()} style={{ marginBottom: "10px" }}>
          {!isProfileScreen() && (
            <div style={{ fontWeight: "bold", color: "black" }}>
              {textFor("personDetails:")}
            </div>
          )}
          {formData.isEditing ? (
            <>
              <button
                id="personDetailsClearButton"
                className={commonStyles.blackButton}
                type="button"
                onClick={handleClearClick}
              >
                {textFor("clear")}
              </button>
              <button
                id="personDetailsSaveButton"
                className={commonStyles.greenButton}
                type="submit"
              >
                SAVE
              </button>
              <button
                id="personDetailsCancelButton"
                className={commonStyles.orangeButton}
                type="button"
                onClick={handleCancelClick}
              >
                CANCEL
              </button>
            </>
          ) : (
            <>
              <button
                className={commonStyles.darkRedButton}
                type="button"
                id="personDetailsEditButton"
                onClick={handleEditClick}
              >
                {textFor("edit")}
              </button>
              {/* <button
                className={commonStyles.blueButton}
                type="button"
                onClick={handleGoBack}
              >
                Go back
              </button> */}
            </>
          )}
        </div>
        <div className={styles.profileImageSection}>
          <CircularImage profileImageUrl={formData.profileImage} />
          {formData.isEditing ? (
            <>
              <button
                id="changeProfilePicButton"
                className={commonStyles.magentaButton}
                type="button"
                onClick={() => setEnableCaptureImage(true)}
              >
                {textFor("change")}
              </button>
              {!formData.isDefaultImageUsed && (
                <button
                  id="deleteProfilePicButton"
                  className={commonStyles.aquaButton}
                  type="button"
                  onClick={useDefaultImageClicked}
                >
                  {textFor("delete")}
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
  return (
    <form onSubmit={proceedToSubmitForm}>
      <div className={styles.formWrapper}>
        <div className={styles.formContent}>
          <div className={styles.imageAndRightSideOptions}>
            {getImageAndChangeButtonsView()}
            {getNameBirthGenderGotraView()}

            {
              //When showing view for Self detaiils or userProfile, the relation will be SELF
              //it will be blank or other values for member
              formData.familyRelationType !== "SELF"
                ? getRelationshipTypeView()
                : ""
            }
            {getFoodMarriageEducationView()}

            {getBodyIncomeOtherFieldsView()}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditableMemberDetailsView;
