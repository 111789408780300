import { Form, Link, json, redirect } from "react-router-dom";
import { isAPhoneNumber, isAnEmail } from "../../util/fieldChecker";
import PasswordSetupFields from "../../components/passwordSetup/PasswordSetupFields";
import { BASE_URL } from "../../Constants";

function SetRegistrationPassword() {
  return (
    <div>
      <h2>SetPassword</h2>
      <Form method="post">
        <PasswordSetupFields />
      </Form>

      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </div>
  );
}
export default SetRegistrationPassword;

export async function action({ request, params }) {
  console.log("setPassword action is called.. request = ", request);
  const data = await request.formData();
  let passwordSetupData = {
    userId: localStorage.getItem("userId"),
    otp: data.get("otp").trim(),
    password: data.get("password").trim(),
  };
  const userInput = data.get("phoneOrEmail").trim();
  let url = BASE_URL + "/normalUser/";
  if (isAPhoneNumber(userInput)) {
    passwordSetupData.phone = localStorage.getItem("phoneOrEmail");
    url += "registerPasswordWithPhoneOtp";
  } else if (isAnEmail(userInput)) {
    passwordSetupData.email = localStorage.getItem("phoneOrEmail");
    url += "registerPasswordWithEmailOtp";
  } else {
    return null;
  }

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(passwordSetupData),
  });

  if (!response.ok) {
    throw json({ message: "Could not register." }, { status: 500 });
  }
  if (response.ok) {
    console.log("password Setup response is ok.");
    // localStorage.removeItem("phoneOrEmail");
    // localStorage.removeItem("userId");
    return redirect("/login");
  }
}
