import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { doNavigate } from "../../util/auth";
import { Form, json, Link, useNavigate, useNavigation } from "react-router-dom";
import { isAPhoneNumber, isAnEmail } from "../../util/fieldChecker";
import { BASE_URL, USER_INPUT_TYPE } from "../../Constants";
import commonStyles from "../../commonStyles.module.scss";
import { Loader } from "../../components/UI/ErrorModal";
import { textFor } from "../../util/languages";
import { saveMaskedPhoneOrEmail } from "../../util/viewUtils";

function ForgotPassword() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [password, setPassword] = useState("welcome");
  const [otp, setOtp] = useState("");
  const [isResetPasswordOtpAvailable, setIsResetPasswordOtpAvailable] =
    useState("");
  useEffect(() => {
    //TODO THIS SHOULD NOT BE REUIRED AS WE ARE ALREADY USING ROUTER LOADER
    if (isAuthenticated) {
      // Navigate to "/dashboard" route
      // Example with useNavigate from React Router
      doNavigate(navigate, "/dashboard");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (localStorage.getItem("resetPasswordOtp")) {
      setIsResetPasswordOtpAvailable("1");
      setOtp(localStorage.getItem("resetPasswordOtp"));
    }
  }, [isSubmitting]);
  const handlePhoneOrEmailChange = (e) => {
    setPhoneOrEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleOtpChange = (e) => {
    if (e.target.value.length < 7) {
      setOtp(e.target.value);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("resetPasswordOtp")) {
      setIsResetPasswordOtpAvailable("1");
      setOtp(localStorage.getItem("resetPasswordOtp"));
    }
  }, [isSubmitting]);

  function getTextForEmail() {
    const savedPhoneOrEmail = localStorage.getItem("phoneOrEmail");
    return textFor("yourEmailIs") + savedPhoneOrEmail;
  }

  function getTextForPhone() {
    const savedPhoneOrEmail = localStorage.getItem("phoneOrEmail");
    return textFor("yourPhoneIs") + savedPhoneOrEmail;
  }

  function getTextToSendOtp() {
    const savedOtp = localStorage.getItem("resetPasswordOtp");

    if (localStorage.getItem(USER_INPUT_TYPE) === "email") {
      return (
        <div>
          <p>{textFor("resetRequestAccepted")}</p>
          <br />
          <div style={{ marginLeft: "5px" }}>
            <span>{textFor("sendOtpOnEmail1")}</span>
            <br />
            <span>{textFor("quicklyAfterRequest")}</span>
            <br />
            <span>{textFor("sendOtpOnEmail2")}</span>
            <br />
            <span>
              {textFor("sendOtpOnEmail3")}
              <strong>{"R " + savedOtp}</strong>
            </span>
            <br />
            <span>{textFor("sendOtpOnEmail4")}</span>
          </div>
          <p>{getTextForEmail()}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>{textFor("registrationStarted")}</p>
          <br />
          <div style={{ marginLeft: "5px" }}>
            <span>{textFor("sendOtpOnPhone1")}</span>
            <br />
            <span>{textFor("quicklyAfterRequest")}</span>
            <br />
            <span>{textFor("sendOtpOnPhone3")}</span>
            <br />
            <span>
              {textFor("sendOtpOnPhone4")}
              <strong>{"A " + savedOtp}</strong>
            </span>
          </div>
          <p>{getTextForPhone()}</p>
        </div>
      );
    }
  }

  function resetClicked(e) {
    if (window.confirm(textFor("areYouSure"))) {
      localStorage.removeItem("otp");
      localStorage.removeItem(USER_INPUT_TYPE);
      setIsResetPasswordOtpAvailable("");
      setOtp("");
      setPhoneOrEmail("");
      setPassword("");
    }
  }
  return (
    <div className={commonStyles.pageBackground}>
      {isSubmitting && <Loader />}
      <h2 className={commonStyles.pageTitle}>{textFor("passwordReset")}</h2>
      {!isResetPasswordOtpAvailable && (
        <Form method="post">
          <br />
          <br />
          <div>
            <label className={commonStyles.fieldLabel} htmlFor="phoneOrEmail">
              {textFor("phoneOrEmail:")}
            </label>
            <br />
            <input
              className={commonStyles.inputField}
              type="text"
              id="phoneOrEmail"
              autoComplete="off"
              name="phoneOrEmail"
              placeholder="+910123456789 / a@b.com"
              value={phoneOrEmail}
              onChange={handlePhoneOrEmailChange}
            />

            <div style={{ marginTop: "1vh" }}>
              <label className={commonStyles.fieldLabel} htmlFor="password">
                {textFor("password:")}
              </label>
              <br />
              <input
                className={commonStyles.inputField}
                type="password"
                id="password"
                value={password}
                name="password"
                autoComplete="off"
                placeholder={textFor("passwordPlaceholder")}
                onChange={handlePasswordChange}
              />
            </div>

            <div style={{ marginTop: "1vh" }}>
              <label className={commonStyles.fieldLabel} htmlFor="otp">
                {textFor("otp")}
              </label>
              <br />
              <input
                className={commonStyles.inputField}
                type="number"
                id="otp"
                value={otp}
                autoComplete="off"
                maxLength="6"
                name="otp"
                placeholder={textFor("any6DigitNumber")}
                onChange={handleOtpChange}
              />
            </div>
          </div>
          <br />
          <button
            className={commonStyles.pageSubmitBtn}
            type="submit"
            disabled={isSubmitting}
          >
            {textFor("submit")}
          </button>
        </Form>
      )}
      <div>
        {isResetPasswordOtpAvailable && (
          <div>
            {getTextToSendOtp()}
            <p style={{ paddingRight: "15vw" }}>
              {textFor("resetWillTake24Hours")}
            </p>
            <button
              className={commonStyles.pageSubmitBtn}
              type="button"
              onClick={resetClicked}
            >
              {textFor("reset")}
            </button>
          </div>
        )}
      </div>

      <div className={commonStyles.bottomSection}>
        <p style={{ color: "white" }}>
          {textFor("youHaveAccountDetails?")}
          <Link style={{ color: "blue", marginLeft: "2vw" }} to="/login">
            {textFor("loginButton")}
          </Link>
        </p>
        <p style={{ color: "white" }}>
          {textFor("dontHaveAnAccount?")}
          <Link style={{ color: "blue", marginLeft: "2vw" }} to="/register">
            {textFor("registerButton")}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default ForgotPassword;

export async function action({ request, params }) {
  console.log("forgotPassword action is called.. request = ", request);
  const data = await request.formData();
  const otp = data.get("otp");
  let registrationData = {
    password: data.get("password"),
    otp: otp,
  };
  const userInput = data.get("phoneOrEmail").trim();
  let url = BASE_URL + "/normalUser/";
  if (isAPhoneNumber(userInput)) {
    registrationData.phone = userInput;
    url += "generateResetPasswordRequestUsingPhone";
  } else if (isAnEmail(userInput)) {
    registrationData.email = userInput;
    url += "generateResetPasswordRequestUsingEmail";
  } else {
    return null;
    // throw json(
    //   { message: "Please input a valid phone or email" },
    //   { status: 500 }
    // );
  }

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(registrationData),
  });

  if (!response.ok) {
    throw json({ message: "Could not register." }, { status: 500 });
  }
  console.log("response  : ", response);
  saveMaskedPhoneOrEmail(userInput);
  localStorage.setItem("resetPasswordOtp", otp);
  return "";
}
